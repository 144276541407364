/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-14 14:49:09
 * @LastEditTime: 2023-10-25 14:08:18
 * @Description: 新建 适合于首页逻辑
 * @FilePath: /knowledgeforge/src/components/LayoutCooper/Header/NewCreate/index.js
 *
 */
import { Fragment, useEffect, useState } from 'react';
import { Button, Popover } from 'antd';
import { intl } from 'di18n-react';
import { useSelector } from 'react-redux';
import {
  CreateFolder,
  CreateCollabNoname,
  CreateImportWord,
  CreateTemplate,
} from '@/assets/icon/index';
import { poFlow, poMind, smDocNew, smSheetNew, smSlide } from '@/assets/icon/fileIcon';
import { CreateType } from '@/constants/cooper';
import CreateFolderFun from '@/components/CooperOperation/CreateFolder';
import CreateCollabFun from '@/components/CooperOperation/CreateCollab';
import ImportCollab from '@/components/CooperOperation/ImportCollab';
import { getIds } from '@/utils/file';
import Template from '../Template/index';
import miniBus from '@/utils/miniBus';
// import { apolloSwitch, CooperPOCreate } from '@/utils/ab';
import './index.less';

const { DOCUMENT2, SHEET2, SLIDE, ANONYMOUS, DIAGRAMING, MINDMAP } = CreateType;

function NewCreate(params) {
  const [showTemplate, setShowTemplate] = useState(false);
  const [hovered, setHovered] = useState(false);
  const { IsExternalTenant } = useSelector((state) => state.GlobalData);
  const { source, isOuter } = params;
  // const [showPO, setShowPO] = useState(false);

  // useEffect(async () => {
  //   apolloSwitch(CooperPOCreate).then((res) => {
  //     setShowPO(res);
  //   });
  // }, [])

  const _createFolder = () => {
    handleHoverChange(false);
    CreateFolderFun(() => {
      const { pathname } = window.location;
      // 个人空间和团队空间
      if (/^\/disk$|^\/files\/|^\/team-file\//.test(pathname)) {
        miniBus.dispatchEvent('folderCreated');
      }
    });
  };

  const _createCollab = (type) => {
    const { parentId, teamId } = getIds();
    if (type === DOCUMENT2) {
      window.__OmegaEvent('ep_create_docs2_ck', '', {
        platform: 'new',
      });
    } else if (type === SHEET2) {
      window.__OmegaEvent('ep_create_sheets2_ck', '', {
        platform: 'new',
      });
    } else if (type === SLIDE) {
      window.__OmegaEvent('ep_create_slides_ck', '', {
        platform: 'new',
        source,
      });
    } else if (type === ANONYMOUS) {
      window.__OmegaEvent('ep_create_anonymousdoc_ck', '', {
        platform: 'new',
        source,
      });
    } else if (type === DIAGRAMING) {
      window.__OmegaEvent('ep_create_po_diagraming_ck', '', {
        platform: 'new',
        source,
      });
    } else if (type === MINDMAP) {
      window.__OmegaEvent('ep_create_po_mindmap_ck', '', {
        platform: 'new',
        source,
      });
    }

    CreateCollabFun(type, parentId, teamId);
    handleHoverChange(false);
  };

  const _chooseTemplate = () => {
    window.__OmegaEvent('ep_template_ck', '', {
      platform: 'new',
    });
    setShowTemplate(!showTemplate);
    handleHoverChange(false);
  };

  const handleHoverChange = (open) => {
    setHovered(open);
  };

  const _handleImport = (e) => {
    handleHoverChange(false);
    const { target } = e;
    const { parentId, teamId } = getIds();
    ImportCollab(target.files[0], 0, parentId, teamId, () => {
      target.value = '';
    });
  };

  const _importCollab = () => {
    window.__OmegaEvent('ep_create_onlinedocs_ck', '', {
      platform: 'new',
      source,
    });
    document.getElementById('docs-file').click();
  };

  const _renderContent = () => {
    return (
      <div className="create">
        {!IsExternalTenant && isOuter && <div className="tenant-tips">{intl.t('当前为外部空间，只支持创建以下文档')}</div>}
        <div className="create-folder">
          <div
            className="create-li"
            onClick={_createFolder}>
            <img src={CreateFolder} />
            <span>{intl.t('新建文件夹')}</span>
          </div>
        </div>
        <div className="create-collab">
          <div
            className="create-li"
            onClick={() => _createCollab(SHEET2)}>
            <img src={smSheetNew} />
            <span>{intl.t('协作表格')}</span>
          </div>
          <div
            className="create-li"
            onClick={() => _createCollab(DOCUMENT2)}
          >
            <img src={smDocNew} />
            <span>{intl.t('协作文档')}</span>
          </div>
          {/* 外部空间及租户禁用 */}
          {
            (!isOuter && !IsExternalTenant) && (
              <>
                <div
                  className="create-li"
                  onClick={() => _createCollab(DIAGRAMING)}
                >
                  <img src={poFlow} />
                  <span>{intl.t('协作流程图')}</span>
                  <div className='create-li-new'>new</div>
                  <div className='create-li-beta'>Beta</div>
                </div>
                <div
                  className="create-li"
                  onClick={() => _createCollab(MINDMAP)}>
                  <img src={poMind} />
                  <span>{intl.t('协作思维导图')}</span>
                  <div className='create-li-new'>new</div>
                  <div className='create-li-beta'>Beta</div>
                </div>
              </>
            )
          }

          {
            (!isOuter && !IsExternalTenant) && (
              <div
                className="create-li"
                onClick={() => _createCollab(SLIDE)}>
                <img src={smSlide} />
                <span>{intl.t('协作幻灯片')}</span>
              </div>)
          }
          {
            (!isOuter && !IsExternalTenant) && (
              <div
                className="create-li"
                onClick={() => _createCollab(ANONYMOUS)}>
                <img src={CreateCollabNoname} />
                <span>{intl.t('匿名文档')}</span>
              </div>
            )
          }
        </div>
        {/* <div className="create-collab-old">
           <div
             className="create-li"
             onClick={() => _createCollab(SHEET)}>
             <img src={CreateCollabSheet} />
             <span>{intl.t('协作表格')}</span>
             <span className="old-tag">{intl.t('旧版')}</span>
           </div>
           <div
             className="create-li"
             onClick={() => _createCollab(DOCUMENT)}>
             <img src={CreateCollabWord} />
             <span>{intl.t('协作文档')}</span>
             <span className="old-tag">{intl.t('旧版')}</span>
           </div>
           <div
             className="create-li"
             onClick={() => _createCollab(SLIDE)}>
             <img src={CreateCollabPpt} />
             <span>{intl.t('协作幻灯片')}</span>
             <span className="old-tag">{intl.t('旧版')}</span>
           </div>
          </div> */}
        <div
          className="create-import"
          onClick={_importCollab}>
          <div className="create-li">
            <img src={CreateImportWord} />
            <span>{intl.t('导入为在线文档')}</span>
          </div>
        </div>
        {
         (!isOuter && !IsExternalTenant)
            && (<div className="create-template">
              <div
                className="create-li"
                onClick={_chooseTemplate}>
                <img src={CreateTemplate} />
                <span>{intl.t('从模版库新建')}</span>
              </div>
            </div>)
        }
        <li style={{ display: 'none' }}>
          <input
            type="file"
            id="docs-file"
            onChange={_handleImport}
            accept=".docx,.doc,.md,.txt,.xlsx,.xls,.csv,.xlsm"
          />
        </li>
      </div>
    );
  };

  return (
    <Fragment>
      <Popover
        placement="bottomRight"
        content={_renderContent()}
        trigger="hover"
        visible={hovered}
        onVisibleChange={handleHoverChange}
      >
        <Button
          icon={<i className="dk-iconfont dk-icon-a-" />}
          type="primary"
          onClick={() => { window.__OmegaEvent('ep_create_ck'); }}>
          {intl.t('新建')}
        </Button>
      </Popover>
      {showTemplate && (
        <Template
          showTemplate={showTemplate}
          handleCancel={() => {
            setShowTemplate(false);
          }}
        />
      )}
    </Fragment>
  );
}

export default NewCreate;
